<template>
  <v-card>
    <v-card-title class="text-h6">
      SKU: {{ skuDetail.sku }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="fs-16px font-weight-medium black--text">Chi tiết</div>
      <custom-table
        ref="customTable"
        table-height="calc(100vh - 500px)"
        :columns="columns"
        :default-filters="{
          id_inventory_request: skuDetail.id_inventory_request,
          id_goods: skuDetail.id_goods,
        }"
        :footer-actions="footerActions"
        :is-page-show="true"
        api-url="/inventory/v1/history-list"
        @showDetailDialog="showDetailDialog"
      ></custom-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  INVENTORY_DEFAULT,
  INVENTORY_DETAIL_STATUS_OPTIONS,
  INVENTORY_TYPE_OPTIONS,
  INVENTORY_CLASSIFY_OPTIONS,
} from "@/libs/const";

export default {
  name: "InventoryHistory",
  props: {
    skuDetail: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: { ...INVENTORY_DEFAULT },
    count: {},
    isLoading: false,
    items: [],
    statusOptions: [...INVENTORY_DETAIL_STATUS_OPTIONS],
    typeOptions: [...INVENTORY_TYPE_OPTIONS],
    classifyOptions: [...INVENTORY_CLASSIFY_OPTIONS],

    columns: [],
    footerActions: [],
    sums: [],
  }),
  computed: {
    isDisabledBtn() {
      return false;
    },
  },
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.position"),
        placeholder: this.$t("labels.position"),
        name: "cell_id",
        hasSort: true,
        sortName: "cell_id",
        key: "cell_id",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.quantity"),
        placeholder: this.$t("labels.quantity"),
        name: "quantity",
        hasSort: true,
        sortName: "quantity",
        key: "quantity",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.identity_check"),
        placeholder: this.$t("labels.identity_check"),
        name: "identity_name",
        hasSort: true,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.time_check"),
        placeholder: this.$t("labels.time_check"),
        name: "inventory_time",
        hasSort: true,
        sortName: "inventory_time",
        key: "inventory_time",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    async getCustomer() {
      this.item = { ...this.item, id_customer: null };
      this.customerOptions = [];
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        {
          id_warehouse: this.item.id_warehouse,
          is_active: true,
        }
      );
      const options = data.map((e) => ({
        value: e.id,
        text: e.company_name || "",
      }));
      this.customerOptions = [...options];
    },
    cancel() {
      this.$emit("cancel");
    },
    showDetailDialog(item) {
      this.skuDetail = { ...item };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.skuDetail = {};
      this.detailDialog = false;
    },
  },
};
</script>

<style scoped></style>
